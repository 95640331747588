import { Fragment } from 'react';
import { Button, Container, Image, Nav, Row, Col } from 'react-bootstrap';

function FocusComponent({ Focus }: any) {
	return (
		<>
			<div className="bgwhite">
				<Container className="padding-60 padding-50">
					<Col md={12}>
						<Row>
							{Focus.map((items: any, i: any) => (
								<Fragment key={i}>
									<Col
										key={i}
										md={5}
										className="mobilePadding"
									>
										<Image
											src={items.image}
											className="LeftImage"
										/>
										<div key={i}>
											{items.content.map(
												(item: any, i: any) => (
													<p
														key={i}
														className="imageParagraph textjustify"
													>
														{item.para}
													</p>
												)
											)}
										</div>
									</Col>
									<Col md={7}>
										<p className="paragraph customPadding textjustify">
											{items.paragraph}
										</p>
										<div className="paragraphTitle">
											{items.title1}
										</div>
										<ul className="ul textjustify">
											{items.lists1.map(
												(item: any, i: any) => (
													<li key={i}>{item.list}</li>
												)
											)}
										</ul>
										<div className="paragraphTitle">
											{items.title2}
										</div>
										<ul className="ul">
											{items.lists2.map(
												(item: any, i: any) => (
													<li key={i}>{item.list}</li>
												)
											)}
										</ul>
									</Col>
								</Fragment>
							))}
						</Row>
					</Col>
				</Container>
			</div>
		</>
	);
}
export default FocusComponent;
