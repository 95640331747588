import { Splide, SplideSlide } from '@splidejs/react-splide';
import '@splidejs/splide/dist/css/themes/splide-default.min.css';
import { useEffect, useRef, useState } from 'react';
import { Button, Col, Image, Modal, Nav, Row } from 'react-bootstrap';
import ArrowNext from '../assets/static/images/Arrow_1.svg';
import ArrowPrev from '../assets/static/images/Arrow_2.svg';
import { useAppContext } from '../components/context';
import Freetrialform from '../components/home/freetrialform';

const SliderComponent = ({ Slider }: any) => {
	const splideRef = useRef<any>(null);
	const [data, setData] = useState([]);
	const [modalform, setModal] = useState(Boolean);
	const { isDemoFormOpenState } = useAppContext();
	const [isDemoFormOpen, setIsDemoFormOpen] = isDemoFormOpenState;
	const spildeOption = {
		rewind: false,
		autoplay: true,
		type: 'loop',
		arrows: false,
		interval: 5000,
	};

	useEffect(() => {
		fetch('/mockdata/home.json')
			.then((res) => res.json())
			.then((res) => setData(res));
	}, []);

	useEffect(() => {
		if (isDemoFormOpen) {
			handleAutoPlay('pause');
		} else {
			handleAutoPlay();
		}
	}, [isDemoFormOpen]);

	const handleAutoPlay = (action = 'play') => {
		const Autoplay = splideRef?.current?.splide?.Components?.Autoplay;
		if (action === 'pause') {
			Autoplay.pause(99);
		} else {
			Autoplay.play(99);
		}
	};

	const handleshowModal = () => {
		handleAutoPlay('pause');
		setModal(true);
	};

	const handleCloseModal = () => {
		handleAutoPlay();
		setModal(false);
	};

	const handleClick = () => {
		window.open('https://mentorerp.com/', '_blank', 'noreferrer');
	};

	return (
		<div id="carousalerp">
			{Slider ? (
				<Splide
					ref={splideRef}
					options={spildeOption}
					id="mainSlider"
					renderControls={() => (
						<div className="splide__arrows  splide__arrows-firstcompo">
							<Button
								variant="default"
								className="splide__arrow splide__arrow--prev"
							>
								<Image src={ArrowPrev} />
							</Button>
							<Button
								variant="default"
								className="splide__arrow splide__arrow--next"
							>
								<Image src={ArrowNext} />
							</Button>
						</div>
					)}
				>
					{Slider.map((item: any, i: any) => (
						<SplideSlide key={i}>
							<Image
								src={item.image}
								className="w-100 img-responsive d-none d-xl-block d-xl-blcok"
							/>
							<Image
								src={item.mobileImage}
								className="w-100 img-responsive d-xl-none d-xl-blcok"
							/>
							<div className="sliderContent">
								<h5>{item.title}</h5>
								<p className="mgbottom50">{item.content}</p>
								<Button
									className="btn txtwhite custombtn padding-carousel"
									onClick={handleClick}
								>
									Explore Now
								</Button>
								<Button
									onMouseDown={handleshowModal}
									className="btn txtwhite freeTrialBtn"
								>
									Free Trail
								</Button>

								<Modal
									centered
									show={modalform}
									onHide={handleCloseModal}
									className="modalRounded"
									size="lg"
								>
									<Modal.Body className="p-0">
										<Row>
											{data?.map((item: any, i: any) => (
												<Col
													md="12"
													lg="7"
													className="modalRounded"
													key={i}
												>
													{item.FreetrialFormImage.map(
														(x: any, i: any) => (
															<Image
																key={i}
																src={
																	x.formimage
																}
																alt={
																	x.formimage
																}
																className="modalImg"
															/>
														)
													)}
												</Col>
											))}
											<Col
												md="12"
												lg="5"
												className="mobilewidth"
											>
												<Button
													variant="default"
													className="close modalClose"
													onClick={handleCloseModal}
												>
													&times;
												</Button>
												<Freetrialform
													onClose={handleCloseModal}
												/>
											</Col>
										</Row>
									</Modal.Body>
								</Modal>
							</div>
						</SplideSlide>
					))}
				</Splide>
			) : null}
		</div>
	);
};

export { SliderComponent };
