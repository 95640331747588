import { Button, Container, Image, Nav, Row, Col } from 'react-bootstrap';

function Development({ development }: any) {
	return (
		<>
			<div className="bgGreey" id="mobiledev">
				<Container className="paddingBottom30">
					{development.map((items: any, i: any) => (
						<div key={i}>
							<h2 className="txtblue paddingBottom30">
								{items.title}
							</h2>
							<Row key={i}>
								{items.imageset1.map((item: any, i: any) => (
									<Col
										xs={6}
										sm={4}
										md={4}
										lg={2}
										className="imghover p-10"
										key={i}
									>
										<div className="circle mx-auto">
											<Image
												className="img-fluid rounded-circle"
												src={item.image}
											/>
										</div>
										<div className="boxContent mx-auto">
											<span>{item.text}</span>
										</div>
									</Col>
								))}
							</Row>
							<Row className="paddingBottom50">
								{items.imageset2.map((item: any, i: any) => (
									<Col
										key={i}
										xs={6}
										sm={4}
										md={4}
										lg={2}
										className="imghover p-10"
									>
										<div className="circle mx-auto">
											<Image
												className="img-fluid rounded-circle"
												src={item.image}
											/>
										</div>
										<div className="boxContent mx-auto">
											<span>{item.text}</span>
										</div>
									</Col>
								))}
							</Row>
						</div>
					))}
				</Container>
			</div>
		</>
	);
}
export default Development;
